import React from 'react'
import Index from '../../../Index'
import PagesIndex from '../../../PagesIndex'

export default function WhyChoose() {
  return (
    <>

      <Index.Box className="why-choose-section">
        <Index.Box className="container">
          <Index.Box className="section-title-main">
            <Index.motion.div
              animate={{ y: 250, opacity: 0, }}
              transition={{ duration: 0.8, delay: 0.1 }}
              whileInView={{ y: 0, opacity: 1, }}>
              <Index.Typography className="section-title section-title-white">
                Why choose <span className='section-highlight-text'>Glorion</span>
              </Index.Typography>
              <Index.Typography className="section-title-para">
                Excepteur sint occaecat cupidatat non proident, sunt in culpa qui offici
              </Index.Typography>
            </Index.motion.div>
          </Index.Box>
          <Index.Box className="choose-row">
          <Index.motion.div
              animate={{ y: 100, opacity: 0, }}
              transition={{ duration: 0.8, delay: 0.1 }}
              whileInView={{ y: 0, opacity: 1, }}>
            <Index.Box className="choose-col">
              <Index.Box className="choose-round-main">
                <Index.Box className="choose-round-fill">
                  <img src={PagesIndex.Svg.chooseIcon1} className="choose-logo" alt="logo" />
                </Index.Box>
              </Index.Box>
              <Index.Typography className="choose-title">Lorem ipsum dolor</Index.Typography>
              <Index.Typography className="choose-para">Lorem ipsum dolor sit amet,
                consectetur adipiscing elit, sed </Index.Typography>
            </Index.Box>
            </Index.motion.div>

            <Index.motion.div
              animate={{ y: 200, opacity: 0, }}
              transition={{ duration: 0.8, delay: 0.1 }}
              whileInView={{ y: 0, opacity: 1, }}>
            <Index.Box className="choose-col">
              <Index.Box className="choose-round-main">
                <Index.Box className="choose-round-fill">
                  <img src={PagesIndex.Svg.chooseIcon2} className="choose-logo" alt="logo" />
                </Index.Box>
              </Index.Box>
              <Index.Typography className="choose-title">Lorem ipsum dolor</Index.Typography>
              <Index.Typography className="choose-para">Lorem ipsum dolor sit amet,
                consectetur adipiscing elit, sed </Index.Typography>
            </Index.Box>
            </Index.motion.div>


            <Index.motion.div
              animate={{ y: 300, opacity: 0, }}
              transition={{ duration: 0.8, delay: 0.1 }}
              whileInView={{ y: 0, opacity: 1, }}>
            <Index.Box className="choose-col">
              <Index.Box className="choose-round-main">
                <Index.Box className="choose-round-fill">
                  <img src={PagesIndex.Svg.chooseIcon3} className="choose-logo" alt="logo" />
                </Index.Box>
              </Index.Box>
              <Index.Typography className="choose-title">Lorem ipsum dolor</Index.Typography>
              <Index.Typography className="choose-para">Lorem ipsum dolor sit amet,
                consectetur adipiscing elit, sed </Index.Typography>
            </Index.Box>
            </Index.motion.div>

            <Index.motion.div
              animate={{ y: 400, opacity: 0, }}
              transition={{ duration: 0.8, delay: 0.1 }}
              whileInView={{ y: 0, opacity: 1, }}>
            <Index.Box className="choose-col">
              <Index.Box className="choose-round-main">
                <Index.Box className="choose-round-fill">
                  <img src={PagesIndex.Svg.chooseIcon4} className="choose-logo" alt="logo" />
                </Index.Box>
              </Index.Box>
              <Index.Typography className="choose-title">Lorem ipsum dolor</Index.Typography>
              <Index.Typography className="choose-para">Lorem ipsum dolor sit amet,
                consectetur adipiscing elit, sed </Index.Typography>
            </Index.Box>
            </Index.motion.div>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </>
  )
}
