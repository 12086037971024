import React, { useCallback, useEffect, useState } from 'react'
import Index from '../../../Index'
import PagesIndex from '../../../PagesIndex';

export default function Faq() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [id, setId] = useState(0);

  const toggleAccordion = (index) => {
    setId((prevIndex) => (prevIndex === index ? null : index));
  };

  const handleGetData = useCallback(() => {
    PagesIndex.apiGetHandler(PagesIndex.Api.GET_FAQ).then((res) => {
      setLoading(false);
      if (res.status === 200) {
        setData(res?.data);
      }
    });
  }, []);

  useEffect(() => {
    handleGetData();
  }, [handleGetData])

  return (
    <>
      <Index.Box className="faq-section">
        <Index.Box className="container">
          <Index.Box className="section-title-main">
            <Index.motion.div
              animate={{ y: 250, opacity: 0, }}
              transition={{ duration: 0.8, delay: 0.1 }}
              whileInView={{ y: 0, opacity: 1, }}>
              <Index.Typography className="section-title section-title-faq">
              Frequently ask questions
              </Index.Typography>
            </Index.motion.div>
          </Index.Box>
          <Index.Box className="accrodion-main">
            {
              data?.map((ele, index) => {
                return (
                  <Index.Accordion
                    key={index}
                    defaultExpanded={id === index}
                    className="accrodion"
                    onClick={() => toggleAccordion(index)}>
                    <Index.AccordionSummary
                      expandIcon={<Index.ExpandMoreIcon />}
                      aria-controls="panel1-content"
                      id="panel1-header"
                      className="accrodion-summary"
                    >
                      {ele?.question}
                    </Index.AccordionSummary>
                    <Index.AccordionDetails className="accrodion-details">
                      {ele?.answer}
                    </Index.AccordionDetails>
                  </Index.Accordion>
                )
              })
            }
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </>
  )
}
