import React, { useState } from 'react'
import Index from '../../../Index'
import ReactApexChart from 'react-apexcharts'

export default function Tokenomics() {
  const [pai, setPie] = useState({
    series: [44, 55, 41, 17, 15 , 25],
    options: {
      chart: {
        type: 'donut',
        expanndOnClick: false
      },
      fill: {
        colors: ['#FFA523', '#00B4B0', '#9F49A3', '#FF3169', '#c85803', '#0068b9']
      },
      stroke: {
        show: false
      },
      dataLabels: {
        enabled: false,
      },
      tooltip: {
        enabled: false,
      },
      marker: {
        show: false,
      },
      legend: {
        show: false
      },
      // responsive: [{
      //   breakpoint: 480,
      //   options: {
      //     chart: {
      //       width: 200
      //     },
      //   }
      // }]
    },
  })
  return (
    <>

      <Index.Box className="token-section">
        <Index.Box className="container">
          <Index.Box className="section-title-main">
            <Index.Typography className='section-backword-sub-title'>Introduction to the</Index.Typography>
            <Index.motion.div
              animate={{ y: 250, opacity: 0, }}
              transition={{ duration: 0.8, delay: 0.1 }}
              whileInView={{ y: 0, opacity: 1, }}>
              <Index.Typography className="section-title section-title-token">
                Glorion Tokenomics
              </Index.Typography>
            </Index.motion.div>
          </Index.Box>
          <Index.Box sx={{ width: 1 }} className="grid-main">
            <Index.Box display="grid" gridTemplateColumns="repeat(12, 1fr)" gap={{ xs: 2, sm: 2, md: 3, lg: 5 }} className="token-row">
              <Index.Box gridColumn={{ xs: "span 12", sm: "span 12", md: "span 6", lg: "span 6" }} className="grid-column">
                <Index.Box className="tokenomics-content-main">
                  <Index.motion.div
                    animate={{ x: -300, opacity: 0, }}
                    transition={{ duration: 0.8, delay: 0.1 }}
                    whileInView={{ x: 0, opacity: 1, }}>
                    <Index.Box className="token-chart-main">
                      <ReactApexChart options={pai.options} series={pai.series} type="donut" />
                      <Index.Box className="token-chart-value-box">
                        <Index.Typography className="token-chart-value-lable">Total Supply</Index.Typography>
                        <Index.Typography className="token-chart-value-text">100,000,000,000</Index.Typography>
                      </Index.Box>
                    </Index.Box>
                  </Index.motion.div>
                </Index.Box>
              </Index.Box>
              <Index.Box gridColumn={{ xs: "span 12", sm: "span 12", md: "span 6", lg: "span 6" }} className="grid-column">
                <Index.Box className="tokenomics-content-main">
                  <Index.Box className="tokenomics-content-box">
                    <Index.Box className="tokenomics-content-left">
                      <Index.Box className="token-content-wraper">
                        <Index.Typography className="tokenomics-content-title">40% Public Pool</Index.Typography>
                        {/* <Index.Typography className="tokenomics-content-para">Lorem ipsum dolor sit amet, consectetur</Index.Typography> */}
                      </Index.Box>
                    </Index.Box>
                    <Index.Box className="tokenomics-content-right">
                      <Index.Typography className="tokenomics-content-lable">Amount</Index.Typography>
                      <Index.Typography className="tokenomics-content-digit">40,000,000,000</Index.Typography>
                    </Index.Box>
                  </Index.Box>
                  <Index.Box className="tokenomics-content-box">
                    <Index.Box className="tokenomics-content-left">
                      <Index.Box className="token-content-wraper">
                        <Index.Typography className="tokenomics-content-title">20% Reserve Pool</Index.Typography>
                        {/* <Index.Typography className="tokenomics-content-para">Lorem ipsum dolor sit amet, consectetur</Index.Typography> */}
                      </Index.Box>
                    </Index.Box>
                    <Index.Box className="tokenomics-content-right">
                      <Index.Typography className="tokenomics-content-lable">Amount</Index.Typography>
                      <Index.Typography className="tokenomics-content-digit">20,000,000,000</Index.Typography>
                    </Index.Box>
                  </Index.Box>
                  <Index.Box className="tokenomics-content-box">
                    <Index.Box className="tokenomics-content-left">
                      <Index.Box className="token-content-wraper">
                        <Index.Typography className="tokenomics-content-title">
                        20% Burn Pool</Index.Typography>
                        {/* <Index.Typography className="tokenomics-content-para">Lorem ipsum dolor sit amet, consectetur</Index.Typography> */}
                      </Index.Box>
                    </Index.Box>
                    <Index.Box className="tokenomics-content-right">
                      <Index.Typography className="tokenomics-content-lable">Amount</Index.Typography>
                      <Index.Typography className="tokenomics-content-digit">20,000,000,000</Index.Typography>
                    </Index.Box>
                  </Index.Box>
                  <Index.Box className="tokenomics-content-box">
                    <Index.Box className="tokenomics-content-left">
                      <Index.Box className="token-content-wraper">
                        <Index.Typography className="tokenomics-content-title">5% Team Pool</Index.Typography>
                        {/* <Index.Typography className="tokenomics-content-para">Lorem ipsum dolor sit amet, consectetur</Index.Typography> */}
                      </Index.Box>
                    </Index.Box>
                    <Index.Box className="tokenomics-content-right">
                      <Index.Typography className="tokenomics-content-lable">Amount</Index.Typography>
                      <Index.Typography className="tokenomics-content-digit">5,000,000,000</Index.Typography>
                    </Index.Box>
                  </Index.Box>
                  <Index.Box className="tokenomics-content-box">
                    <Index.Box className="tokenomics-content-left">
                      <Index.Box className="token-content-wraper">
                        <Index.Typography className="tokenomics-content-title">5% Rewards Pool</Index.Typography>
                        {/* <Index.Typography className="tokenomics-content-para">Lorem ipsum dolor sit amet, consectetur</Index.Typography> */}
                      </Index.Box>
                    </Index.Box>
                    <Index.Box className="tokenomics-content-right">
                      <Index.Typography className="tokenomics-content-lable">Amount</Index.Typography>
                      <Index.Typography className="tokenomics-content-digit">5,000,000,000</Index.Typography>
                    </Index.Box>
                  </Index.Box>
                  <Index.Box className="tokenomics-content-box">
                    <Index.Box className="tokenomics-content-left">
                      <Index.Box className="token-content-wraper">
                        <Index.Typography className="tokenomics-content-title">
                        10% Development/Marketing Pool</Index.Typography>
                        {/* <Index.Typography className="tokenomics-content-para">Lorem ipsum dolor sit amet, consectetur</Index.Typography> */}
                      </Index.Box>
                    </Index.Box>
                    <Index.Box className="tokenomics-content-right">
                      <Index.Typography className="tokenomics-content-lable">Amount</Index.Typography>
                      <Index.Typography className="tokenomics-content-digit">10,000,000,000</Index.Typography>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </>
  )
}
