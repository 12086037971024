import React, { useCallback, useEffect, useState } from 'react'
import Index from '../../../Index'
import PagesIndex from '../../../PagesIndex'

export default function Team() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  const handleGetData = useCallback(() => {
    PagesIndex.apiGetHandler(PagesIndex.Api.GET_TEAM).then((res) => {
      setLoading(false);
      if (res.status === 200) {
        setData(res?.data);
      }
    });
  }, []);

  useEffect(() => {
    handleGetData();
  }, [handleGetData])

  return (
    <>
      <Index.Box className="team-section">
        <Index.Box className="container">
          <Index.Box className="section-title-main">
            <Index.Typography className="section-backword-title">Leadership team</Index.Typography>
            <Index.motion.div
              animate={{ y: 250, opacity: 0, }}
              transition={{ duration: 0.8, delay: 0.1 }}
              whileInView={{ y: 0, opacity: 1, }}>
              <Index.Typography className="section-title section-title-team">
                Meet our expert
              </Index.Typography>
            </Index.motion.div>
            <Index.Typography className="section-title-para section-title-para-black">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
            </Index.Typography>
          </Index.Box>
          <Index.Box sx={{ width: 1 }} className="grid-main">
            <Index.Box display="grid" gridTemplateColumns="repeat(12, 1fr)" gap={{ xs: 2, sm: 2, md: 2, lg: 4 }} className="team-row">
              {
                data?.map((ele) => {
                  return (
                    <Index.Box gridColumn={{ xs: "span 12", sm: "span 4", md: "span 3", lg: "span 3" }} className="grid-column">
                      <Index.Box className="team-card">
                        <img src={ele?.image ? process.env.REACT_APP_IMAGE_ENDPOINT + ele?.image : PagesIndex.Svg.TeamImg1} alt='Team' className='team-img' />
                        <Index.Box className="team-content-main">
                          <Index.Typography className="team-title">{ele?.name}</Index.Typography>
                          <Index.Typography className="team-sub-title">{ele?.position}</Index.Typography>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                  )
                })
              }
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </>
  )
}
