
import customer from './images/svg/customer.svg';
import copyIcon from './images/svg/copy.svg'
import heroCoin from './images/svg/hero-coin.svg'
import TeamImg1 from './images/svg/team-img1.svg'
import TeamImg2 from './images/svg/team-img2.svg'
import TeamImg3 from './images/svg/team-img3.svg'
import TeamImg4 from './images/svg/team-img4.svg'
import twitterIcon from './images/svg/twitter.svg'
import LinkdinIcon from './images/svg/linkdin.svg'
import youtubeIcon from './images/svg/youtube.svg'
import chooseIcon1 from './images/svg/choose-icon1.svg'
import chooseIcon2 from './images/svg/choose-icon2.svg'
import chooseIcon3 from './images/svg/choose-icon3.svg'
import chooseIcon4 from './images/svg/choose-icon4.svg'
import nextArrow from './images/svg/next-arrow.svg'
import prevArrow from './images/svg/prev-arrow.svg'
import whiteLine from './images/svg/white-lines.svg'
import greenLines from './images/svg/green-lines.svg'
import mobilemenuIcon from './images/svg/menu.svg'
import closeIcon from './images/svg/close.svg'
import blogImg from './images/svg/blog-img.svg'
import rightarrowpink from './images/svg/rigt-arrow-pink.svg'
import pinkDateIcon from './images/svg/pink-date-icon.svg'
import facebookWhiteIcon from './images/svg/facebook-white.svg'
import instgramWhiteIcon from './images/svg/instgram-white.svg'
import twitterWhiteIcon from './images/svg/twitter-white.svg'











const Svg = {
    customer,
    copyIcon,
    heroCoin,
    TeamImg1,
    TeamImg2,
    TeamImg3,
    TeamImg4,
    twitterIcon,
    LinkdinIcon,
    youtubeIcon,
    chooseIcon1,
    chooseIcon2,
    chooseIcon3,
    chooseIcon4,
    prevArrow,
    nextArrow,
    whiteLine,
    greenLines,
    mobilemenuIcon,
    closeIcon,
    blogImg,
    rightarrowpink,
    pinkDateIcon,
    twitterWhiteIcon,
    facebookWhiteIcon,
    instgramWhiteIcon
}

export default Svg;