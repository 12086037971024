import axios from "axios";

const REACT_APP_API_URL = "http://192.168.1.93:3002/api/admin/";

const DataService = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  // baseURL: REACT_APP_API_URL,
});

export default DataService;
