import logo from './images/png/logo.png';













const Png = {
      logo,

}

export default Png;