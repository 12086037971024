import React from 'react'
import PagesIndex from '../../../PagesIndex'
import Index from '../../../Index'

export default function BlogRecentPost() {
      return (
            <>
                  <Index.Box className="blog-recent-list-main">
                        <Index.Link className="blog-recent-list-box">
                              <Index.Box className="blog-recent-list-img-box">
                                    <img src={PagesIndex.Svg.blogImg} alt='Blog' className='blog-recent-list-img' />
                              </Index.Box>
                              <Index.Box className="blog-recet-content-main">
                                    <Index.Typography className='blog-recent-list-text'>Lorem ipsum dolor sit amet, consectetur adipiscing</Index.Typography>
                                    <Index.Box className="blog-resent-date-flex">
                                          <img src={PagesIndex.Svg.pinkDateIcon} alt='Blog' className='blog-resent-date-icon' />
                                          <Index.Typography className='blog-recent-date-text'> 7 March, 2024</Index.Typography>
                                    </Index.Box>
                              </Index.Box>
                        </Index.Link>

                        <Index.Link className="blog-recent-list-box">
                        <Index.Box className="blog-recent-list-img-box">
                                    <img src={PagesIndex.Svg.blogImg} alt='Blog' className='blog-recent-list-img' />
                              </Index.Box>
                              <Index.Box className="blog-recet-content-main">
                                    <Index.Typography className='blog-recent-list-text'>Lorem ipsum dolor sit amet, consectetur adipiscing</Index.Typography>
                                    <Index.Box className="blog-resent-date-flex">
                                          <img src={PagesIndex.Svg.pinkDateIcon} alt='Blog' className='blog-resent-date-icon' />
                                          <Index.Typography className='blog-recent-date-text'> 7 March, 2024</Index.Typography>
                                    </Index.Box>
                              </Index.Box>
                        </Index.Link>

                        <Index.Link className="blog-recent-list-box">
                        <Index.Box className="blog-recent-list-img-box">
                                    <img src={PagesIndex.Svg.blogImg} alt='Blog' className='blog-recent-list-img' />
                              </Index.Box>
                              <Index.Box className="blog-recet-content-main">
                                    <Index.Typography className='blog-recent-list-text'>Lorem ipsum dolor sit amet, consectetur adipiscing</Index.Typography>
                                    <Index.Box className="blog-resent-date-flex">
                                          <img src={PagesIndex.Svg.pinkDateIcon} alt='Blog' className='blog-resent-date-icon' />
                                          <Index.Typography className='blog-recent-date-text'> 7 March, 2024</Index.Typography>
                                    </Index.Box>
                              </Index.Box>
                        </Index.Link>

                        <Index.Link className="blog-recent-list-box">
                        <Index.Box className="blog-recent-list-img-box">
                                    <img src={PagesIndex.Svg.blogImg} alt='Blog' className='blog-recent-list-img' />
                              </Index.Box>
                              <Index.Box className="blog-recet-content-main">
                                    <Index.Typography className='blog-recent-list-text'>Lorem ipsum dolor sit amet, consectetur adipiscing</Index.Typography>
                                    <Index.Box className="blog-resent-date-flex">
                                          <img src={PagesIndex.Svg.pinkDateIcon} alt='Blog' className='blog-resent-date-icon' />
                                          <Index.Typography className='blog-recent-date-text'> 7 March, 2024</Index.Typography>
                                    </Index.Box>
                              </Index.Box>
                        </Index.Link>
                  </Index.Box>
            </>
      )
}
