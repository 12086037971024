import React from 'react'
import Index from '../../../Index'
import PagesIndex from '../../../PagesIndex'


export default function Home() {
  return (
    <>
      <Index.Box className="home-hero-main">
        <PagesIndex.HeroSection />
      </Index.Box>
      <Index.Box className="headline-main">
        <PagesIndex.HeadlineSlider />
      </Index.Box>
      <Index.Box className="why-choose-main" id="why-choose-main">
        <PagesIndex.WhyChoose />
      </Index.Box>
      <Index.Box className="tokenomics-main" id="tokenomics-main">
        <PagesIndex.Tokenomics />
      </Index.Box>
      <Index.Box className="roadmap-main" id="roadmap-main">
        <PagesIndex.Roadmap />
      </Index.Box>
      <Index.Box className="section-wrapper">
        <Index.Box className="team-main"  id="team-main">
          <PagesIndex.Team />
        </Index.Box>
        <Index.Box className="faq-main" id="faq-main">
          <PagesIndex.Faq />
        </Index.Box>
      </Index.Box>
    </>
  )
}
