import React from 'react'
import Index from '../../../Index'
import PagesIndex from '../../../PagesIndex'

export default function BlogList() {
      return (
            <>
                  <Index.Box className="inner-page-banner-main blog-details-bg">
                        <Index.Box className="container">
                              <Index.Box className="inner-page-banner-content">
                                    <Index.Typography className='inner-page-heading'>
                                          Blog List
                                    </Index.Typography>
                                    <Index.Box className='inner-page-breadcrumb'>
                                          <Index.Typography className='inner-page-breadcrumb-link'>
                                                Home
                                          </Index.Typography>
                                          <Index.Typography className='inner-page-breadcrumb-link inner-page-breadcrumb-link-gap'>
                                                /
                                          </Index.Typography>
                                          <Index.Typography className='inner-page-breadcrumb-link'>
                                                Blog List
                                          </Index.Typography>
                                    </Index.Box>
                              </Index.Box>
                        </Index.Box>
                  </Index.Box>
                  <Index.Box className="inner-page-main">
                        <Index.Box className="blog-section">
                              <Index.Box className="container">
                                    <Index.Box className="blog-flex">
                                          <Index.Box className="blog-left-main">
                                                <Index.Box className="blog-card">
                                                      <Index.Box className="blog-card-inner-left">
                                                            <Index.Box className="blog-card-img">
                                                                  <img src={PagesIndex.Svg.blogImg} alt='Blog' className='blog-img' />
                                                                  <Index.Box className="blog-card-btn-main">
                                                                        <Index.Button className='blog-card-btn'>
                                                                              Crypto
                                                                        </Index.Button>
                                                                  </Index.Box>
                                                            </Index.Box>
                                                      </Index.Box>
                                                      <Index.Box className="blog-card-inner-right">
                                                            <Index.Typography className='blog-card-heading'>
                                                                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                                                            </Index.Typography>
                                                            <Index.Typography className='blog-card-para'>
                                                                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
                                                            </Index.Typography>
                                                            <Index.Box className="blog-profile-flex">
                                                                  <Index.Box className="blog-profile-img">
                                                                        <img src={PagesIndex.Png.logo} alt='Blog' className='blog-profile-img' />
                                                                  </Index.Box>
                                                                  <Index.Box className="blog-profile-content-main">
                                                                        <Index.Typography className='blog-profile-name'>
                                                                              Glorion
                                                                        </Index.Typography>
                                                                        <Index.Typography className='blog-profile-date'>
                                                                              June 11th, 2024
                                                                        </Index.Typography>
                                                                  </Index.Box>
                                                            </Index.Box>
                                                      </Index.Box>
                                                </Index.Box>

                                                <Index.Box className="blog-card">
                                                      <Index.Box className="blog-card-inner-left">
                                                            <Index.Box className="blog-card-img">
                                                                  <img src={PagesIndex.Svg.blogImg} alt='Blog' className='blog-img' />
                                                                  <Index.Box className="blog-card-btn-main">
                                                                        <Index.Button className='blog-card-btn'>
                                                                              Crypto
                                                                        </Index.Button>
                                                                  </Index.Box>
                                                            </Index.Box>
                                                      </Index.Box>
                                                      <Index.Box className="blog-card-inner-right">
                                                            <Index.Typography className='blog-card-heading'>
                                                                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                                                            </Index.Typography>
                                                            <Index.Typography className='blog-card-para'>
                                                                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
                                                            </Index.Typography>
                                                            <Index.Box className="blog-profile-flex">
                                                                  <Index.Box className="blog-profile-img">
                                                                        <img src={PagesIndex.Png.logo} alt='Blog' className='blog-profile-img' />
                                                                  </Index.Box>
                                                                  <Index.Box className="blog-profile-content-main">
                                                                        <Index.Typography className='blog-profile-name'>
                                                                              Glorion
                                                                        </Index.Typography>
                                                                        <Index.Typography className='blog-profile-date'>
                                                                              June 11th, 2024
                                                                        </Index.Typography>
                                                                  </Index.Box>
                                                            </Index.Box>
                                                      </Index.Box>
                                                </Index.Box>

                                                <Index.Box className="blog-card">
                                                      <Index.Box className="blog-card-inner-left">
                                                            <Index.Box className="blog-card-img">
                                                                  <img src={PagesIndex.Svg.blogImg} alt='Blog' className='blog-img' />
                                                                  <Index.Box className="blog-card-btn-main">
                                                                        <Index.Button className='blog-card-btn'>
                                                                              Crypto
                                                                        </Index.Button>
                                                                  </Index.Box>
                                                            </Index.Box>
                                                      </Index.Box>
                                                      <Index.Box className="blog-card-inner-right">
                                                            <Index.Typography className='blog-card-heading'>
                                                                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                                                            </Index.Typography>
                                                            <Index.Typography className='blog-card-para'>
                                                                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
                                                            </Index.Typography>
                                                            <Index.Box className="blog-profile-flex">
                                                                  <Index.Box className="blog-profile-img">
                                                                        <img src={PagesIndex.Png.logo} alt='Blog' className='blog-profile-img' />
                                                                  </Index.Box>
                                                                  <Index.Box className="blog-profile-content-main">
                                                                        <Index.Typography className='blog-profile-name'>
                                                                              Glorion
                                                                        </Index.Typography>
                                                                        <Index.Typography className='blog-profile-date'>
                                                                              June 11th, 2024
                                                                        </Index.Typography>
                                                                  </Index.Box>
                                                            </Index.Box>
                                                      </Index.Box>
                                                </Index.Box>

                                                <Index.Box className="blog-card">
                                                      <Index.Box className="blog-card-inner-left">
                                                            <Index.Box className="blog-card-img">
                                                                  <img src={PagesIndex.Svg.blogImg} alt='Blog' className='blog-img' />
                                                                  <Index.Box className="blog-card-btn-main">
                                                                        <Index.Button className='blog-card-btn'>
                                                                              Crypto
                                                                        </Index.Button>
                                                                  </Index.Box>
                                                            </Index.Box>
                                                      </Index.Box>
                                                      <Index.Box className="blog-card-inner-right">
                                                            <Index.Typography className='blog-card-heading'>
                                                                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                                                            </Index.Typography>
                                                            <Index.Typography className='blog-card-para'>
                                                                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
                                                            </Index.Typography>
                                                            <Index.Box className="blog-profile-flex">
                                                                  <Index.Box className="blog-profile-img">
                                                                        <img src={PagesIndex.Png.logo} alt='Blog' className='blog-profile-img' />
                                                                  </Index.Box>
                                                                  <Index.Box className="blog-profile-content-main">
                                                                        <Index.Typography className='blog-profile-name'>
                                                                              Glorion
                                                                        </Index.Typography>
                                                                        <Index.Typography className='blog-profile-date'>
                                                                              June 11th, 2024
                                                                        </Index.Typography>
                                                                  </Index.Box>
                                                            </Index.Box>
                                                      </Index.Box>
                                                </Index.Box>
                                                <Index.Box className="blog-card">
                                                      <Index.Box className="blog-card-inner-left">
                                                            <Index.Box className="blog-card-img">
                                                                  <img src={PagesIndex.Svg.blogImg} alt='Blog' className='blog-img' />
                                                                  <Index.Box className="blog-card-btn-main">
                                                                        <Index.Button className='blog-card-btn'>
                                                                              Crypto
                                                                        </Index.Button>
                                                                  </Index.Box>
                                                            </Index.Box>
                                                      </Index.Box>
                                                      <Index.Box className="blog-card-inner-right">
                                                            <Index.Typography className='blog-card-heading'>
                                                                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                                                            </Index.Typography>
                                                            <Index.Typography className='blog-card-para'>
                                                                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
                                                            </Index.Typography>
                                                            <Index.Box className="blog-profile-flex">
                                                                  <Index.Box className="blog-profile-img">
                                                                        <img src={PagesIndex.Png.logo} alt='Blog' className='blog-profile-img' />
                                                                  </Index.Box>
                                                                  <Index.Box className="blog-profile-content-main">
                                                                        <Index.Typography className='blog-profile-name'>
                                                                              Glorion
                                                                        </Index.Typography>
                                                                        <Index.Typography className='blog-profile-date'>
                                                                              June 11th, 2024
                                                                        </Index.Typography>
                                                                  </Index.Box>
                                                            </Index.Box>
                                                      </Index.Box>
                                                </Index.Box>
                                          </Index.Box>
                                          <Index.Box className="blog-right-main">
                                                <Index.Box className="blog-category-main gray-card">
                                                      <Index.Box className="blog-inner-title-main">
                                                            <Index.Typography className='blog-inner-title'>
                                                                  Categories
                                                            </Index.Typography>
                                                      </Index.Box>
                                                      <PagesIndex.BlogCategory />
                                                </Index.Box>
                                                <Index.Box className="blog-recent-main gray-card">
                                                      <Index.Box className="blog-inner-title-main">
                                                            <Index.Typography className='blog-inner-title'>
                                                                  Recent Post
                                                            </Index.Typography>
                                                      </Index.Box>
                                                      <PagesIndex.BlogRecentPost />
                                                </Index.Box>
                                          </Index.Box>
                                    </Index.Box>
                              </Index.Box>
                        </Index.Box>
                  </Index.Box>
            </>
      )
}
