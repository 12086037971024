import { useEffect, useState } from "react";
import Index from "../../../Index";
import { useLocation } from "react-router-dom";
import PagesIndex from "../../../PagesIndex";



export default function CmsManagement() {
      const location = useLocation();
      const [loader, setLoader] = useState(true);
      const [data, setData] = useState({});
      const handleGetData = () => {
            setLoader(true)
            PagesIndex.apiGetHandler(PagesIndex.Api.GET_CMS).then((res) => {
                  setLoader(false)
                  if (res.status === 200 || res.status === 201) {
                        if (location.pathname === "/cms/privacy-policy") {
                              setData(res?.data?.privacy_policy);
                        } else if (location.pathname === "/cms/terms-and-conditions") {
                              setData(res?.data?.term_condition);
                        }
                  } else {
                        PagesIndex.toasterError(res?.message);
                        setLoader(false)
                  }
            });
      };
      useEffect(() => {
            handleGetData();
      }, []);
      return (
            <>
                  <Index.Box className="inner-page-banner-main blog-details-bg">
                        <Index.Box className="container">
                              <Index.Box className="inner-page-banner-content">
                                    <Index.Typography className='inner-page-heading'>
                                          {location.pathname === "/cms/privacy-policy"
                                                ? "Privacy Policy"
                                                : "Terms & Conditions"}
                                    </Index.Typography>
                                    <Index.Box className='inner-page-breadcrumb'>
                                          <Index.Typography className='inner-page-breadcrumb-link'>
                                                Home
                                          </Index.Typography>
                                          <Index.Typography className='inner-page-breadcrumb-link inner-page-breadcrumb-link-gap'>
                                                /
                                          </Index.Typography>
                                          <Index.Typography className='inner-page-breadcrumb-link'>
                                                {location.pathname === "/cms/privacy-policy"
                                                      ? "Privacy Policy"
                                                      : "Terms & Conditions"}
                                          </Index.Typography>
                                    </Index.Box>
                              </Index.Box>
                        </Index.Box>
                  </Index.Box>

                  <Index.Box className="inner-page-main">
                        <Index.Box className="container">
                              <Index.Box className="editer-content-main">
                                    {loader ? (
                                          <PagesIndex.Loader />
                                    ) : (
                                          <Index.Typography
                                                className="inner-page-para"
                                                dangerouslySetInnerHTML={{ __html: data }}
                                          ></Index.Typography>
                                    )}
                              </Index.Box>
                        </Index.Box>
                  </Index.Box>
            </>
      )
}
