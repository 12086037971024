import React, { useState } from 'react'
import Index from '../../../container/Index';
import PagesIndex from '../../../container/PagesIndex';

export default function Header() {

  const [isActive, setIsActive] = useState(false);
  const handleClick = event => {
    // 👇️ toggle isActive state on click
    setIsActive(current => !current);
    document.body.classList[isActive ? "remove" : "add"]('body-overflow');
  };

  const handleClickMenuItem = (id) => {
    var element = document.getElementById(id)
    element.scrollIntoView({ behavior: "smooth", block: "start" });
    handleClick();
  }
  return (
    <>
      <Index.Box className="header-main">
        <Index.Box className="container">
          <Index.Box className="header-row">
            <Index.Box className="header-cust-col">
              <Index.Link className="header-logo-box" to={"/"}>
                <img src={PagesIndex.Png.logo} className='header-logo' alt='logo' />
              </Index.Link>
            </Index.Box>
            <Index.Box className="header-cust-col">
              <Index.Box className={`header-nav-main ${isActive ? "mobilemenu-active" : ""}`}>
                <Index.Box className="mobile-navbar-show">
                  <Index.Box className="mobile-navbar-head">
                    <Index.Box className="mobile-navbar-logo-flex">
                      <img src={PagesIndex.Png.logo} className='header-nav-logo' alt='logo' />
                      <Index.Typography className='mobile-navbar-title'>Glorion</Index.Typography>
                    </Index.Box>

                    <Index.Button className='mobile-navbar-close-btn' onClick={handleClick}>
                      <img src={PagesIndex.Svg.closeIcon} className='mobile-navbar-close' alt='close' />
                    </Index.Button>
                  </Index.Box>
                </Index.Box>
                <Index.List className='header-nav-ul'>
                  <Index.ListItem className='header-nav-li'>
                    <Index.Link className='header-nav-link' onClick={() => {
                      handleClickMenuItem("why-choose-main")
                    }}>About Us</Index.Link>
                  </Index.ListItem>
                  <Index.ListItem className='header-nav-li'>
                    <Index.Link className='header-nav-link' onClick={() => {
                      handleClickMenuItem("tokenomics-main")
                    }}>Tokenomics</Index.Link>
                  </Index.ListItem>
                  <Index.ListItem className='header-nav-li'>
                    <Index.Link className='header-nav-link' onClick={() => {
                      handleClickMenuItem("roadmap-main")
                    }}>Roadmap</Index.Link>
                  </Index.ListItem>
                  <Index.ListItem className='header-nav-li'>
                    <Index.Link className='header-nav-link' onClick={() => {
                      handleClickMenuItem("team-main")
                    }}>Our Team</Index.Link>
                  </Index.ListItem>
                  <Index.ListItem className='header-nav-li'>
                    <Index.Link className='header-nav-link' onClick={() => {
                      handleClickMenuItem("faq-main")
                    }}>FAQs</Index.Link>
                  </Index.ListItem>
                  <Index.ListItem className='header-nav-li'>
                    <Index.Link className='header-nav-link' to={"/blog-list"}>Blog</Index.Link>
                  </Index.ListItem>
                </Index.List>
              </Index.Box>
            </Index.Box>
            <Index.Box className="header-cust-col">
              <Index.Box className="header-right-main header-btn-main">
                <PagesIndex.BorderButton btnLabel="Whitepaper" className='border-btn' />
                <PagesIndex.PrimaryButton onClick={() => window.open(process.env.REACT_APP_PRSALE_URL, '_blank', 'noopener,noreferrer')} btnLabel="Buy Now" className='primary-btn header-btn' />
                <Index.Button className='mobile-menu-btn' onClick={handleClick}>
                  <img src={PagesIndex.Svg.mobilemenuIcon} className='mobile-menu-icon' />
                </Index.Button>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </>
  )
}
