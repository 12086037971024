import React from 'react'
import Index from '../../../Index'
import PagesIndex from '../../../PagesIndex'

export default function BlogDetails() {
      return (
            <>
                  <Index.Box className="inner-page-banner-main blog-details-bg">
                        <Index.Box className="container">
                              <Index.Box className="inner-page-banner-content">
                                    <Index.Typography className='inner-page-heading'>
                                          Blog Detail
                                    </Index.Typography>
                                    <Index.Box className='inner-page-breadcrumb'>
                                          <Index.Typography className='inner-page-breadcrumb-link'>
                                                Home
                                          </Index.Typography>
                                          <Index.Typography className='inner-page-breadcrumb-link inner-page-breadcrumb-link-gap'>
                                                /
                                          </Index.Typography>
                                          <Index.Typography className='inner-page-breadcrumb-link'>
                                                Blog Name
                                          </Index.Typography>
                                    </Index.Box>
                              </Index.Box>
                        </Index.Box>
                  </Index.Box>
                  <Index.Box className="inner-page-main">
                        <Index.Box className="blog-section">
                              <Index.Box className="container">
                                    <Index.Box className="blog-flex">
                                          <Index.Box className="blog-left-main">
                                                <Index.Box className="blog-deatils-card">
                                                      <Index.Box className="blog-deatils-card-img">
                                                            <img src={PagesIndex.Svg.blogImg} alt='Blog' className='blog-deatils-img' />
                                                      </Index.Box>
                                                      <Index.Box className="blog-resent-date-flex">
                                                            <img src={PagesIndex.Svg.pinkDateIcon} alt='Blog' className='blog-resent-date-icon' />
                                                            <Index.Typography className='blog-recent-date-text'> 7 March, 2024</Index.Typography>
                                                      </Index.Box>
                                                      <Index.Typography className='blog-deatils-card-heading'>
                                                            Lorem ipsum dolor sit amet,consectetur
                                                            adipiscing
                                                      </Index.Typography>
                                                      <Index.Typography className='blog-deatils-card-para'>
                                                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
                                                      </Index.Typography>
                                                </Index.Box>
                                                <Index.Box className="editer-content"></Index.Box>
                                                <Index.Box className="blog-share-main">
                                                      <Index.Typography className='blog-share-text'>Share:</Index.Typography>
                                                      <Index.Box className="blog-share-list-main">
                                                            <Index.List className="blog-share-list">
                                                                  <Index.ListItem className="blog-share-list-item">
                                                                        <Index.Link href='#' className="blog-share-list-link">
                                                                              <img src={PagesIndex.Svg.facebookWhiteIcon} alt='Facebook' className='blog-share-list-icon'/>
                                                                        </Index.Link>
                                                                  </Index.ListItem>
                                                                  <Index.ListItem className="blog-share-list-item">
                                                                        <Index.Link href='#' className="blog-share-list-link">
                                                                              <img src={PagesIndex.Svg.instgramWhiteIcon} alt='Facebook' className='blog-share-list-icon'/>
                                                                        </Index.Link>
                                                                  </Index.ListItem>
                                                                  <Index.ListItem className="blog-share-list-item">
                                                                        <Index.Link href='#' className="blog-share-list-link">
                                                                              <img src={PagesIndex.Svg.twitterWhiteIcon} alt='Facebook' className='blog-share-list-icon'/>
                                                                        </Index.Link>
                                                                  </Index.ListItem>
                                                            </Index.List>
                                                      </Index.Box>
                                                </Index.Box>
                                          </Index.Box>
                                          <Index.Box className="blog-right-main">
                                                <Index.Box className="blog-category-main gray-card">
                                                      <Index.Box className="blog-inner-title-main">
                                                            <Index.Typography className='blog-inner-title'>
                                                                  Categories
                                                            </Index.Typography>
                                                      </Index.Box>
                                                      <PagesIndex.BlogCategory />
                                                </Index.Box>
                                                <Index.Box className="blog-recent-main gray-card">
                                                      <Index.Box className="blog-inner-title-main">
                                                            <Index.Typography className='blog-inner-title'>
                                                                  Recent Post
                                                            </Index.Typography>
                                                      </Index.Box>
                                                      <PagesIndex.BlogRecentPost />
                                                </Index.Box>
                                          </Index.Box>
                                    </Index.Box>
                                    <PagesIndex.BlogArticles/>
                              </Index.Box>
                        </Index.Box>
                  </Index.Box>
            </>
      )
}
