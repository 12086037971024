import DataService from "./DataService";

const apiGetHandler = (url) => {
  return DataService.get(url)
    .then((res) => {
      return {
        data: res?.data?.data,
        status: res.status,
        message: res?.data?.message,
      };
    })
    .catch((err) => {
      return {
        data: err?.response?.data?.data,
        status: err?.response?.status,
        message: err?.response?.data?.message,
      };
    });
};
const apiPostHandler = (url, payload) => {
  DataService.defaults.headers.common["Content-Type"] =
    "application/x-www-form-urlencoded";
  return DataService.post(url, payload)
    .then((res) => {
      return {
        data: res?.data?.data,
        status: res?.status,
        message: res?.data?.message,
      };
    })
    .catch((err) => {
      return {
        data: err?.response?.data?.data,
        status: err?.response?.status,
        message: err?.response?.data?.message,
      };
    });
};
const apiPostHandlerVideoUpload = (url, payload, config) => {
  DataService.defaults.headers.common["Content-Type"] =
    "application/x-www-form-urlencoded";
  return DataService.post(url, payload, config)
    .then((res) => {
      return {
        data: res?.data?.data,
        status: res?.status,
        message: res?.data?.message,
      };
    })
    .catch((err) => {
      return {
        data: err?.response?.data?.data,
        status: err?.response?.status,
        message: err?.response?.data?.message,
      };
    });
};
const apiPostWithFormHandler = (url, payload) => {
  DataService.defaults.headers.common["Content-Type"] = "multipart/form-data";
  return DataService.post(url, payload)
    .then((res) => {
      return {
        data: res?.data?.data,
        status: res?.status,
        message: res?.data?.message,
      };
    })
    .catch((err) => {
      return {
        data: err?.response?.data?.data,
        status: err?.response?.status,
        message: err?.response?.data?.message,
      };
    });
};

export {
  apiGetHandler,
  apiPostHandler,
  apiPostWithFormHandler,
  apiPostHandlerVideoUpload,
};
