import React from 'react'
import PagesIndex from '../../../PagesIndex'
import Index from '../../../Index'

export default function BlogCategory() {
      return (
            <>
                  <Index.Box className="blog-category-list-main">
                        <Index.List className="blog-category-list">
                              <Index.ListItem className='blog-category-list-item'>
                                    <Index.Box className="blog-category-list-box">
                                          <Index.Typography className='blog-category-list-text'>
                                                <img src={PagesIndex.Svg.rightarrowpink} alt='Blog' className='pink-right-arrow' />
                                                Crypto Exchange <span>(10)</span>
                                          </Index.Typography>
                                    </Index.Box>
                              </Index.ListItem>

                              <Index.ListItem className='blog-category-list-item'>
                                    <Index.Box className="blog-category-list-box">
                                          <Index.Typography className='blog-category-list-text'>
                                                <img src={PagesIndex.Svg.rightarrowpink} alt='Blog' className='pink-right-arrow' />
                                                Markets <span>(13)</span>
                                          </Index.Typography>
                                    </Index.Box>
                              </Index.ListItem>

                              <Index.ListItem className='blog-category-list-item'>
                                    <Index.Box className="blog-category-list-box">
                                          <Index.Typography className='blog-category-list-text'>
                                                <img src={PagesIndex.Svg.rightarrowpink} alt='Blog' className='pink-right-arrow' />
                                                Crypto insights <span>(9)</span>
                                          </Index.Typography>
                                    </Index.Box>
                              </Index.ListItem>

                              <Index.ListItem className='blog-category-list-item'>
                                    <Index.Box className="blog-category-list-box">
                                          <Index.Typography className='blog-category-list-text'>
                                                <img src={PagesIndex.Svg.rightarrowpink} alt='Blog' className='pink-right-arrow' />
                                                World <span>(3)</span>
                                          </Index.Typography>
                                    </Index.Box>
                              </Index.ListItem>

                              <Index.ListItem className='blog-category-list-item'>
                                    <Index.Box className="blog-category-list-box">
                                          <Index.Typography className='blog-category-list-text'>
                                                <img src={PagesIndex.Svg.rightarrowpink} alt='Blog' className='pink-right-arrow' />
                                                Crypto world <span>(12)</span>
                                          </Index.Typography>
                                    </Index.Box>
                              </Index.ListItem>

                              <Index.ListItem className='blog-category-list-item'>
                                    <Index.Box className="blog-category-list-box">
                                          <Index.Typography className='blog-category-list-text'>
                                                <img src={PagesIndex.Svg.rightarrowpink} alt='Blog' className='pink-right-arrow' />
                                                Crypto Return <span>(6)</span>
                                          </Index.Typography>
                                    </Index.Box>
                              </Index.ListItem>
                        </Index.List>
                  </Index.Box>
            </>
      )
}
